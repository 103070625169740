@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');





.nav {
    border: 4px solid blue;
    position: absolute;
 
}

.nav-container {
    color: white;
    z-index: 99;
    /* position: fixed;
    top:0; */
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-left: 4px solid transparent;
    border-right: 4px solid transparent; */
    
    /* padding-top: 1rem; */
}

.logo-container {
    max-height: 60px;
    max-width: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.links-container {
    display: flex;
    gap: 1rem;
}

.page-link {
    font-family: 'Raleway';
    font-size: 1rem;
    font-weight: bold;
}

.container-one {
    display: flex;
    align-items: center;
    gap: 2rem;
}
.active {
    text-decoration: underline blue;
  }

.me {
    color: white;
}

.link {
    min-height: 1rem;
    min-width: 1rem;
    cursor: pointer;
    text-decoration: none
}

.current-page {
    font-family: 'Raleway';
    font-size: 1rem;
    font-weight: bold;
}

.link {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .link:hover, .link:focus, .link:active {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  .page-link
 {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .page-link:hover, .page-link:focus, .page-link:active {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);

  }

  .page-link {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
  }
  .page-link:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #34B9DF;
    height: 4px;
    -webkit-transition-property: right;
    transition-property: right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .page-link:hover:before, .page-link:focus:before, .page-link:active:before {
    right: 0;
  }
  .hamburger-container {
    font-size: 1.5rem;
  }

  .container-three {

    transform-origin: 4px 0px;
    background-color: black;
    position: relative;
    min-width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    z-index: 115;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 4px solid transparent;


    border-image: linear-gradient(var(--angle), #34B9DF, #005b75, #a7ecff) 1;
    -webkit-animation: rotate-border 4s linear infinite;
    animation: rotate 4s linear infinite;
    border-radius: 5px;

  }

  .hamburger-nav {
    z-index: 100;
    margin: 0;
    padding: 0;
    background-color: black;
    top: none;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    min-width: 100%;
    justify-content: center;
    align-content: center;
  }

  .hamburger-nav > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nav-container {
    z-index: 100;

  }

  .social-container {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    align-items: center;
  }

  .hamburger-close-container {
    display: flex;
    justify-content: flex-end;
    font-size: 1.5rem;
    padding-top: 1rem;
  }

  .container-three {
    animation: slide-in 0.5s forwards;
  }
  
  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  
  .container-three.reverse {
    animation: slide-out 0.5s forwards;
  }
  
  @keyframes slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
  
  

  @media (max-width: 767px) {
    .pages-container {
        display: none;
    }
    .links-container {
        display: none;
    }
    .logo-container {
        max-height: 50px;
        max-width: 30px;
    }
    

  }


  @media (max-width: 767px) {
    .container {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }

  }