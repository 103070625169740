.gallery-container {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    justify-content: flex-start;
    gap: 1rem;
    flex-grow: 1;
    overflow: scroll;
    /* removing this because it was giving scroll bars at 100% > overflow: scroll; */
}
.gallery-container {
    --angle: 0deg;

}
 
  
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  
  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }
.gallery-title {
    color: white;
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 12px;
    border: 2px solid #34B9DF;
    border-radius: 5px;
    max-width: fit-content;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 5px;
    margin-top: 8rem;

}

.gallery-upper-container {
    display: flex;
    justify-content: space-between;
    border: 2px solid #34B9DF;
    border-radius: 5px;
    padding: 2rem;
    gap: 2rem;
}

.gallery-upper-one {
    max-width: 60%;
    border: 2px solid #34B9DF;
    border-radius: 5px;
    display: flex;
    max-width: 600px;
}

.gallery-upper-two {
    color: white;
    display: flex;
    flex-direction: column;
    text-align: justify;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

}


.title {
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 2rem;
}

.bio-text {
  display: flex;
    margin-top: 1.25rem;
    min-height: 8rem;
    max-height: 8rem;
    min-width: 100%;
    overflow-y: auto;
    border: 2px solid #34B9DF;
    border-radius: 5px;
    flex-grow: 1;
    overflow-x: hidden;
}

.gallery-lower-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.card-container {
    max-height: 10rem;
    max-width: 10rem;
}

.carousel-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    color: #34B9DF;
    font-size: 2rem;
  }


  .mobile-gallery-img {
    border-radius: 5px;
    max-width: 100%;
  }

  .upper-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 35%;
    max-width: 35%;
    overflow: scroll;
  }
 
  .image-border {
    border: 2px solid #34B9DF;
    border-radius: 5px;
    max-width: 60%;

  }

  .upper-two {
    /*display: flex;*/
    flex-direction: column;
    overflow: scroll;
    justify-content: center;

  }

  .upper-image-container {

    border-radius: 5px;
  }

  .gallery-row {
    display: flex;
    align-items: center;
  }

  .gallery-row > img {
  }
  .loading-container {
    position: absolute;
    z-index: 2000;
  }

  .gallery-full-container {
    border: 2px solid #34B9DF;
    border-radius: 5px;
    overflow-y: scroll;
    
  }
.overflow-scroll {
  overflow-x: hidden;
  display: flex;
  padding-right: 1rem;
  min-width: 100%;
  overflow-y: auto;
}
.overflow-scroll::-webkit-scrollbar {
  display: block;
  width: 5px;
  border-radius: 5px;
}

.overflow-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 5px;

}
 
.overflow-scroll::-webkit-scrollbar-thumb {
  background: #34b9df; 
  border-radius: 5px;

}

.overflow-scroll::-webkit-scrollbar-thumb:hover {
  background: #34b9df; 
}


  @media (max-width: 767px) {
    .gallery-upper-container {
      display: none;
    }
    .gallery-container {
        justify-content: flex-start;
    }
    .gallery-full-container {
        display: none;
    }

  }

  @media (max-width: 640px) {
    .gallery-container {
      justify-content: flex-start;
      min-height: 100%;
    }
  }

  @media (max-width: 1024px) {
        .upper-one {
      min-width: 100%;
      max-width: 100%;
    }
  }