.vision-container {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 100%;
    min-height: 4px;
    flex-grow: 1;
    position: relative;
    overflow: scroll;
}

.vision-bg {
    position: absolute;
    z-index: -1;
    min-height: 100%;
    min-width: 100%;
}


.heading {
    all: unset;
    font-family: 'Raleway';
    font-size: 2.375rem;
    line-height: auto;
    font-weight: bold;
    color:white;
    height: auto;
}

.regular-text {
    font-family: 'Raleway';
    font-size: 1rem;
    font-weight: medium;
    text-align: justify;
}

.heading-small {
    all: unset;
    font-family: 'Raleway';
    font-weight: bold;
    color: white;
    font-size: 2rem;
}
.blocks {
    border-radius: 5px;
    border: 2px solid #34B9DF;
}
.block-image {
    border-radius: 5px;
    overflow: hidden;

}


.vision-card {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
    max-width: 100%;
    padding: 2rem;
    background-color: rgba(13, 95, 113, .28);
    margin-top: 4rem;
}
.collapse {
    background-color: rgba(13, 95, 113, .28);
    border: 2px solid #34B9DF;
    border-radius: 5px;
    overflow: hidden; 
}

.collapse-container {
  padding-left: 0;
  padding-right: 0;
  width: 100%
}

.icon {
    max-width: 3rem;
    max-height: 3rem;
}

.vision-card-one {
    font-size: 2rem;
}
.vision-card-one > img {
    min-height: 6rem;
    min-width: 6rem;
}
.icons:hover {
 
}
.first-h {
    line-height: 90%;
}

@-webkit-keyframes hvr-hang {
    0% {
      -webkit-transform: translateY(12px);
      transform: translateY(12px);
    }
    50% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    100% {
      -webkit-transform: translateY(12px);
      transform: translateY(12px);
    }
  }
  @keyframes hvr-hang {
    0% {
      -webkit-transform: translateY(12px);
      transform: translateY(12px);
    }
    50% {
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
    }
    100% {
      -webkit-transform: translateY(12px);
      transform: translateY(12px);
    }
  }
  @-webkit-keyframes hvr-hang-sink {
    100% {
      -webkit-transform: translateY(12px);
      transform: translateY(12px);
    }
  }
  @keyframes hvr-hang-sink {
    100% {
      -webkit-transform: translateY(12px);
      transform: translateY(12px);
    }
  }
  .icons {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .icons:hover, .icons:focus, .icons:active {
    -webkit-animation-name: hvr-hang-sink, hvr-hang;
    animation-name: hvr-hang-sink, hvr-hang;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
  }

.vision-con-two {
    display: flex;
    padding-left: 0;
    padding-right: 0;
}

.collapse-heading {
    font-size: clamp(1rem, 1vw, 2rem);
    line-height: clamp(1rem, 1vw, 1.8rem);
    font-family: 'Raleway';
    font-weight: bold;
}
.collapse:active .aioutlineplus {
    transform: rotate(90deg);
    transition: .2s ease;
}


@media (max-width: 768px) {
    .block-image {
        border: none;
        box-shadow: none;
    }

    .heading-small {
        font-size: clamp(2rem, 4vw, 4rem);
        line-height: clamp(2rem, 4vw, 2.8rem);
    }
    .vision-card {
        max-width: 90%;
    }
    .vision-card-one {
        min-width: 0;
    }
}

@media (max-width: 500px) {
    .heading {
        font-size: clamp(2rem, 4vw, 4rem);
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

  }