.gallery-card-container {
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid transparent;
    z-index: 0;
    position: relative;
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.nft-container {
    max-width: 20%;
}

.gallery-card-container:hover {
    border: 2px solid #34B9DF;
}

.gallery-card-container:hover .gallery-card-overlay {
    display: flex;
    
}

.gallery-card-overlay {
    display: none;
    color:white;
    font-size: 1rem;
    font-weight: bold;
    z-index: 100;
    width: 100%;
    justify-content: center;
    position: absolute;
    align-self: flex-end;
    background: linear-gradient(to bottom, #34B9DF, #a7ecff);
    transition: .3s;

}