.application-container {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 8px);
    min-width: 100%;
    position: relative;
    align-items: center;
    flex-grow: 1;
    overflow: scroll;
  }
  
  .paragraph-container {
    max-height: fit-content;
  }


  
  .paragraph-text {
    color: #ffffff;
  }
  .application-b {

  }
  .radio-border {
    border: 2px solid #34b9df;
    border-radius: 5px;
    padding: 1rem;
  }

  .thank-you-text {
    color: #34b9df;
  }
  
  .textbox {
    padding: 5px;
    font-size: 16px;
    border-width: 2px;
    border-color: #34b9df;
    background-color: #000000;
    color: #ffffff;
    border-style: solid;
    display: flex;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
    text-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
    min-height: 8rem;
    max-width: 35rem; /* Set maximum width to 35rem */
    width: 100%; /* Take up available width */
  }

  .button-container {
    max-width: 35rem; /* Set maximum width to 35rem */
    width: 100%; /* Take up available width */
    padding-left: 0;
    padding-right: 0;

  }

  .book-container {
    min-width: 40%;
    display: flex;
    justify-content: center;
  }
  
  .book {
    max-height: 35rem;
    max-width: 35rem;
  }
  .textbox:focus {
    outline: none;
  }
  
  .textbox::placeholder {
    color: white;
    font-size: 1rem;
    font-family: 'Raleway';
    vertical-align: top;
  }
  
  .question-title {
    margin-bottom: 1rem;
  }
  
  .form-container {
    min-width: 100%;
  }


  .test {
    padding-left: 0;
    padding-right: 0;
  }

  p.paragraph-text > a {
    color: #34b9df;
  }