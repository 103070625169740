@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.home-container {

    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-height: 100vh;
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* overflow: scroll; */
}

.home-container {
    width: 100%;

  }

  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.home-image {
   
    z-index: 1;
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    height: 100%;


}
.overlay-text {
  color: white;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: clamp(2rem, 4vw, 4.235rem);
  line-height: clamp(2rem, 4vw, 4rem);
  position: absolute;
  z-index: 99;
  top: 70vh;
  left: 50%;
  transform: translateX(-50%);
}

.learn-more {
    background-color: #34B9DF;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 1rem;
    font-weight: 700;
    max-width: fit-content;
}

.learn-more:hover {

}


.learn-more {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  .learn-more:hover, .learn-more:focus, .learn-more:active {
    background-color: #2098D1;
    color: white;
  }

  @media (max-width: 500px) {
    .breaks {
    }
    .overlay-text {
        font-size: clamp(2rem, 4vw, 4rem);
        line-height: clamp(2rem, 4vw, 2.8rem);

    }
  }
