@tailwind base;
@tailwind components;
@tailwind utilities;


*, *::before, *::after{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html, body, #root{
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  }


:root {
  --angle: 45deg;

}

::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: "BeyondMars";
  src: local("BeyondMars"),
   url("./assets/BeyondMars.ttf") format("truetype");
  }

  /* .page-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-width: 100vw;  
    position: relative;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    border: 4px solid transparent;
    border-image: linear-gradient(var(--angle), #34B9DF, #005b75, #a7ecff) 1;
    animation: rotate-border 4s linear infinite;
    background-clip: padding-box;
  }
  
 
  
  @keyframes rotate-border {
    to {
      transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes rotate-border {
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes rotate-border {
    to {
      transform: rotate(360deg);
    }
  } */

  .page-container {
    display: flex;
    flex-direction: column;
    width: 100%; 
    max-width: 100vw; 
    position: fixed;
    align-items: center;
    flex-grow: 1;
    /* overflow: scroll; */
    border: 4px solid transparent;
    border-image: linear-gradient(var(--angle), #34B9DF, #005b75, #a7ecff) 1;
    -webkit-animation: rotate-border 4s linear infinite;
    animation: rotate 4s linear infinite;
    height: 100%;
    border-radius: 5px;
  }
  
  @-webkit-keyframes rotate-border {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-moz-keyframes rotate-border {
    from {
      --angle: 0deg;
    }
    to {
      --angle: 360deg;
    }
  }
  
  @keyframes rotate-border {
    to {
      --angle: 360deg;
    }
  }


nav {
  background-color: #333;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 10px;
}

a {
  color: #fff;
  text-decoration: none;
}



.gif {
  border: 2px solid #34B9DF;
  border-radius: 5px;
  max-width: 300px;
  max-height: 300px;
  margin-bottom: 1rem;
}

.loading-bar {
  background-color: #34B9DF;
  border: 2px solid #34B9DF;
  border-radius: 5px;
}

body.black {
  background-color: black;
}

body.transparent {
  background-color: transparent;
}
.loading-container {

  flex-grow: 1;
  /* min-width: 100vw; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}